
import { Mixins, Component } from "vue-property-decorator";
import {
  merchantSettings as query,
  weeklyOrderSummaryNotification,
} from "../api/queries/merchants";
import {
  shippingMethodPublishUpdate,
  paymentOptionUpdateStatus,
  updateWeeklyOrderSummaryNotification,
} from "../api/mutations/merchant";
import NotifierMixin from "@/mixins/NotifierMixin";
import LoaderMixin from "@/mixins/LoaderMixin";

@Component({
  components: {
    PaymentMethods: () => import("../components/MerchantSettingPayments.vue"),
    ShippingMethods: () =>
      import("../components/MerchantSettingShippingMethods.vue"),
  },
  apollo: {
    merchantSettings: {
      query,
      // For optimization: omit this if will be using writeFragment
      fetchPolicy: "cache-and-network",
      variables() {
        return { appUuid: this.$route.params.merchantUuid };
      },
      update<T>({ paymentMethods, shippingMethods }: any) {
        const byNode = ({ node }: { node: T }) => node;
        paymentMethods = paymentMethods.edges
          .map(({ node }: any) => {
            const { appPaymentOption } = node;

            // Map nested objects; for optimization
            if (
              appPaymentOption &&
              appPaymentOption.bankDetails.edges &&
              appPaymentOption.optionDetails.edges
            ) {
              node.appPaymentOption.bankDetails =
                appPaymentOption.bankDetails.edges.map(
                  (bankDetail: any) => bankDetail.node
                );

              node.appPaymentOption.optionDetails =
                appPaymentOption.optionDetails.edges.map(
                  (optionDetail: any) => optionDetail.node
                );
            }

            return node;
          })
          .filter(
            ({
              title,
              gatewayType,
            }: {
              title: string;
              gatewayType: string;
            }) => {
              return !(/^Xendit/.test(title) && /^provider/.test(gatewayType));
            }
          );

        return {
          paymentMethods,
          shippingMethods: shippingMethods.edges.map(byNode),
        };
      },
    },
    weeklyOrderNotif: {
      query: weeklyOrderSummaryNotification,
      variables() {
        return { appUuid: this.$route.params.merchantUuid };
      },
      update(result) {
        return result.fetchAppByUuid.weeklyOrderSummaryNotification;
      },
    },
  },
})
export default class MerchantSettings extends Mixins(
  NotifierMixin,
  LoaderMixin
) {
  private merchantSettings = {
    paymentMethods: [],
    shippingMethods: [],
  };

  private weeklyOrderNotif = Boolean;

  private updatePaymentSetting([active, optionUuid]: [boolean, string]) {
    this.$apollo
      .mutate({
        mutation: paymentOptionUpdateStatus,
        variables: {
          input: { active, optionUuid },
        },
      })
      .then(() => {
        this.showSuccessAlert("Payment Settings updated.");
      })
      .catch((e: any) => {
        this.showErrorAlert(e.message);
      });
  }

  private updateShippingSetting([publish, shippingMethodUuid]: [
    boolean,
    string
  ]) {
    this.$apollo
      .mutate({
        mutation: shippingMethodPublishUpdate,
        variables: {
          input: { publish, shippingMethodUuid },
        },
      })
      .then(() => {
        this.showSuccessAlert("Shipping Method Settings updated.");
      })
      .catch((e: any) => {
        this.showErrorAlert(e.message);
      });
  }

  private updateWeeklyOrderNotif() {
    this.$apollo
      .mutate({
        mutation: updateWeeklyOrderSummaryNotification,
        variables: {
          input: {
            appUuid: this.$route.params.merchantUuid,
            weeklyOrderSummaryNotification: this.weeklyOrderNotif,
          },
        },
      })
      .then(() => {
        this.showSuccessAlert("Weekly Order Summary Mailer Settings updated.");
      })
      .catch((e: any) => {
        this.showErrorAlert(e.message);
      });
  }
}
